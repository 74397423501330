import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Image from '../../shared/Image';
import * as styles from './Industries.module.css';

Industries.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.shape(),
    }),
  ).isRequired,
};
Industries.defaultProps = {};

function Industries({ data }) {
  const { t } = useTranslation('industries');
  const quote = t('quote');
  const author = t('author');
  return (
    <div className={styles.Industries}>
      <ul>
        {data.map(({ title, image }, index) => {
          if (index === 3) {
            return (
              <React.Fragment key={title}>
                <li className={styles.quote}>
                  <blockquote>
                    <p>{quote}</p>
                    <footer>{author}</footer>
                  </blockquote>
                </li>
                <li key={title}>
                  <Image data={image} alt={title} />
                  <span>{title}</span>
                </li>
              </React.Fragment>
            );
          }
          return (
            <li key={title}>
              <Image data={image} />
              <span>{title}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Industries;
