/* eslint-plugin-disable unicorn/filename-case */
import VideoContentBlock from '../blocks/VideoContentBlock';
import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';

import Metadata from '../components/Metadata';
import CmsContentBlock from '../blocks/CmsContentBlock';
import CmsContentBlock2 from '../blocks/CmsContentBlock2';
import CmsContentBlock3 from '../blocks/CmsContentBlock3';
import Industries from '../blocks/Industries';
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../layout/Default';
import HeaderImage from '../blocks/HeaderImage';
// import cleanCockpitData from 'Utils/cleanCockpitData';

import * as styles from './ueber-uns.module.css';

function UeberUnsPage({ data, location }) {
  const { siteData, pageData, history: historyEntries } = data;
  const { title: siteTitle, canonicalSiteUrl } = siteData.siteMetadata;
  const { title, header, leitbild, industries, quality, history, production, metadata, opengraph } = pageData;

  const { t, i18n } = useTranslation('resources');

  console.log('data', data);
  return (
    <DefaultLayout>
      <HeaderImage data={header} />
      <div className={clsx(styles.whiteBackground, styles.ueberuns)} id={t('leitbild')}>
        <CmsContentBlock2 data={leitbild} className="defaultWidth" />
      </div>
      <div className={clsx(styles.greyBackground, styles.ueberuns)} id={t('branchen')}>
        <div className="defaultWidth">
          <CmsContentBlock data={industries} theme="ueberuns" />
          <Industries data={industries.industries} />
        </div>
      </div>
      <div className={clsx(styles.whiteBackground, styles.ueberuns)}>
        <CmsContentBlock3 data={history} className="ueberuns defaultWidth" id={t('firmenchronik')}>
          <dl className={styles.chronik}>
            {historyEntries.edges.map(({ node }) => (
              <React.Fragment key={node.year}>
                <dt>{node.year}</dt>
                <dd>{node.text}</dd>
              </React.Fragment>
            ))}
          </dl>
        </CmsContentBlock3>
      </div>
      <div className={clsx(styles.greyBackground, styles.ueberuns)}>
        <CmsContentBlock3 data={quality} className="ueberuns defaultWidth" id={t('qualitaet')} asidePosition="left" />
      </div>
      <div className={clsx(styles.whiteBackground, styles.ueberuns)}>
        <CmsContentBlock3 data={production} className="ueberuns defaultWidth" id={t('produktion')} />
      </div>
      <div className={clsx(styles.greyBackground, styles.ueberuns)}>
        <VideoContentBlock />
      </div>
      <Metadata
        data={metadata}
        opengraph={opengraph}
        defaultTitle={`${title} : ${siteTitle}`}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
}

export default /* cleanCockpitData( */ UeberUnsPage /* ) */;

export const UeberUnsPageQuery = graphql`
  query UeberUnsPageQuery($language: String) {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pageData: cockpitPageAboutUs(lang: { eq: $language }) {
      title

      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        desiredAspectRatio
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }

      leitbild {
        title
        paragraphs {
          title
          text
          aside
          imageAlign
          image {
            id
            publicURL
            processed: childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: NONE)
            }
          }
        }
      }

      industries {
        title
        paragraphs {
          title
          text
          imageAlign
          image {
            id
            publicURL
            processed: childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: NONE)
            }
          }
        }
        industries {
          title
          image {
            publicURL
            processed: childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: NONE)
            }
          }
        }
      }

      quality {
        title
        paragraphs {
          title
          text
          imageAlign
          image {
            id
            publicURL
            processed: childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: NONE)
            }
          }
        }
      }

      history {
        title
        paragraphs {
          title
          text
          imageAlign
          image {
            id
            publicURL
            processed: childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: NONE)
            }
          }
        }
      }
      production {
        title
        paragraphs {
          title
          text
          imageAlign
          image {
            id
            publicURL
            processed: childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: NONE)
            }
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }

    history: allCockpitHistory(filter: { lang: { eq: $language } }, sort: { fields: year, order: ASC }) {
      edges {
        node {
          id
          year
          text
        }
      }
    }
  }
`;
