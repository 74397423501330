import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Paragraph.module.css';

DefaultParagraph.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape(),
  }),
};
DefaultParagraph.defaultProps = {
  data: null,
};

function DefaultParagraph({ data }) {
  const { title, text } = data;

  return (
    <div className={styles.Paragraph}>
      <React.Fragment>
        {title && <h3>{title}</h3>}
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      </React.Fragment>
    </div>
  );
}

export default DefaultParagraph;
