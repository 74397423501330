import React from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import clsx from 'clsx';

import * as styles from './Paragraph.module.css';
import Image from '../../shared/Image';

const maxWidthLandScapeInPercent = 0.5;
const maxWidthPortraitInPercent = 0.33;

const minWidthLandScapeInPercent = 0.3;
const minWidthPortraitInPercent = 0.2;

const fullWidthBreakPointInPixel = 600;

/**
 * @return {null}
 */
class ParagraphWithImage extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape(),
      imageAlign: PropTypes.string,
    }),
  };

  static defaultProps = {
    data: null,
  };

  state = {
    resizeCount: 0,
    resizeTime: 0,
    width: 50,
    height: 50,
    smartEnabled: true,
  };

  componentDidMount() {
    this.setState({
      resizeCount: 0,
      resizeTime: new Date().getTime(),
      width: 0,
      height: 0,
      smartEnabled: true,
    });
  }

  onResize = (width, height) => {
    // We need to check if the current values are expired and reset to a low
    // height because otherwise the image width is already bigger than the text.
    const { resizeTime, resizeCount } = this.state;
    const { data } = this.props;

    const shouldResetSettings = resizeTime + 1000 < new Date().getTime();
    const smartEnabled = width > fullWidthBreakPointInPixel;
    if (shouldResetSettings) {
      this.setState({
        resizeCount: 0,
        resizeTime: new Date().getTime(),
        width: 0,
        height: 0,
        smartEnabled,
      });
      return;
    }

    if (resizeCount > 3) {
      // we already tried 3 times, we should have a good match now, lets end it
      return;
    }

    const { image } = data;
    const aspectRatio = image ? image.processed && image.processed.fluid.aspectRatio : -1;

    const maxWidthInPercent = aspectRatio >= 1 ? maxWidthLandScapeInPercent : maxWidthPortraitInPercent;
    const minWidthInPercent = aspectRatio >= 1 ? minWidthLandScapeInPercent : minWidthPortraitInPercent;

    const maxImageWidth = width * maxWidthInPercent;
    const minImageWidth = width * minWidthInPercent;

    const maxImageHeight = maxImageWidth / aspectRatio;
    const minImageHeight = minImageWidth / aspectRatio;

    const imageHeight = Math.min(maxImageHeight, height);
    const imageWidth = Math.min(maxImageWidth, imageHeight * aspectRatio);

    this.setState({
      resizeCount: resizeCount + 1,
      resizeTime: new Date().getTime(),
      width: Math.max(minImageWidth, imageWidth),
      height: Math.max(minImageHeight, imageHeight),
      smartEnabled,
    });
  };

  render() {
    const { width, height, smartEnabled } = this.state;
    const { data } = this.props;
    const { title, text, image, imageAlign } = data;

    const imagePosition = imageAlign === 'Rechts' ? 'right' : 'left';

    const containerClasses = clsx(styles.Paragraph, styles.smartEnabled);
    const imageClasses = clsx(styles.image, smartEnabled && styles[imagePosition]);

    return (
      <div className={containerClasses}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
          <React.Fragment>
            {image && (
              <div
                className={imageClasses}
                style={{
                  width: smartEnabled ? `${width}px` : null,
                  height: smartEnabled ? `${height}px` : null,
                }}
              >
                <Image data={image} />
              </div>
            )}
            {title && <h3>{title}</h3>}
            {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
          </React.Fragment>
        </ReactResizeDetector>
      </div>
    );
  }
}

export default ParagraphWithImage;
