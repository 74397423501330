import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Slugger from '../../utils/Slugger';
import Paragraph from '../Paragraph';
import * as styles from './CmsContentBlock.module.css';

CmsContentBlock.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape(),
      }),
    ),
  }),
};
CmsContentBlock.defaultProps = {
  id: '',
  data: false,
  theme: '',
};

function CmsContentBlock({ data, theme, id }) {
  if (!data) {
    return null;
  }

  const { title, paragraphs } = data;

  return (
    <article className={clsx(styles.CmsContentBlock, theme)}>
      <span className={styles.anchor} id={id || Slugger.slug(title)} />
      {title && <h2>{title}</h2>}
      {Array.isArray(paragraphs) &&
        paragraphs.map((paragraph) => <Paragraph data={paragraph} key={`${paragraph.title} + ${paragraph.text}`} />)}
    </article>
  );
}

export default CmsContentBlock;
