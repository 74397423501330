import DefaultParagraph from '../Paragraph/DefaultParagraph';
import Image from '../../components/BackgroundImage/Image';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import * as styles from './CmsContentBlock3.module.css';

CmsContentBlock3.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  asidePosition: PropTypes.oneOf(['left', 'right']),
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape(),
      }),
    ),
  }),
};

CmsContentBlock3.defaultProps = {
  className: '',
  id: null,
  data: null,
  children: null,
  asidePosition: 'right',
};

Aside.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function Aside({ className, data }) {
  return (
    <aside className={className}>
      {data.map((paragraph) => (
        <React.Fragment key={paragraph.title}>
          {paragraph.aside && (
            <DefaultParagraph
              key={paragraph.title}
              data={{
                text: paragraph.aside,
                title: null,
              }}
            />
          )}
          {paragraph.image && <Image data={paragraph.image} />}
        </React.Fragment>
      ))}
    </aside>
  );
}

function CmsContentBlock3(props) {
  const { id, data, className, children, asidePosition } = props;

  if (!data) {
    return null;
  }
  const { title, paragraphs } = data;

  return (
    <section className={clsx(styles.CMSContentBlock, className)} id={id}>
      <h2>{title}</h2>
      {asidePosition === 'left' && <Aside className={asidePosition} data={paragraphs} />}
      <main>
        {paragraphs.map((paragraph) => (
          <React.Fragment key={paragraph.title}>
            <DefaultParagraph data={{ ...paragraph }} />
            {children}
          </React.Fragment>
        ))}
      </main>
      {asidePosition === 'right' && <Aside className={asidePosition} data={paragraphs} />}
    </section>
  );
}

export default CmsContentBlock3;
