import DefaultParagraph from '../Paragraph/DefaultParagraph';
import Image from '../../components/BackgroundImage/Image';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import * as styles from './CmsContentBlock2.module.css';

CmsContentBlock2.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape(),
      }),
    ),
  }),
};
CmsContentBlock2.defaultProps = {
  className: '',
  id: null,
  data: null,
};

function CmsContentBlock2({ id, data, className }) {
  if (!data) {
    return null;
  }
  const { paragraphs } = data;

  return (
    <section className={clsx(styles.CMSContentBlock, className)} id={id}>
      {paragraphs.map((paragraph) => (
        <section key={paragraph.title}>
          <h2>{paragraph.title}</h2>
          <main>
            <DefaultParagraph key={paragraph.title} data={{ ...paragraph, title: '' }} />
          </main>
          <aside>
            {paragraph.aside && (
              <DefaultParagraph key={paragraph.title} data={{ text: paragraph.aside, title: null }} />
            )}
            {paragraph.image && <Image data={paragraph.image} />}
          </aside>
        </section>
      ))}
    </section>
  );
}

export default CmsContentBlock2;
