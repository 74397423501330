import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollLock from 'react-scrolllock';
import clsx from 'clsx';

import Overlay from '../../shared/Overlay';
import videoPreviewImage from '../../assets/images/video-preview.jpg';
import * as styles from './VideoContentBlock.module.css';
import { PortalWithState } from 'react-portal';
import JWVideoPlayer from '../../components/JWVideoPlayer';

VideoContentBlock.propTypes = {};

VideoContentBlock.defaultProps = {};

Aside.propTypes = {};

function Aside() {
  return (
    <aside className="left">
      <PortalWithState
        closeOnOutsideClick
        closeOnEsc
        node={typeof document !== 'undefined' && document.querySelector('#portal')}
      >
        {({ openPortal, closePortal, portal }) => (
          <React.Fragment>
            <button
              style={{
                border: 0,
                padding: 0,
                margin: 0,
              }}
              type="button"
              onClick={openPortal}
              title="NZ Hydraulikzylinder Video"
            >
              <img src={videoPreviewImage} alt="NZ Hydraulikzylinder Video" />
            </button>
            {portal(
              <React.Fragment>
                <ScrollLock />
                <Overlay closePortal={closePortal} theme="video">
                  <JWVideoPlayer />
                </Overlay>
              </React.Fragment>,
            )}
          </React.Fragment>
        )}
      </PortalWithState>
    </aside>
  );
}

function VideoContentBlock() {
  const { t } = useTranslation('video');
  return (
    <section className={clsx(styles.CMSContentBlock, 'defaultWidth')}>
      <h2>{t('title')} </h2>
      <Aside />
      <main>
        <div>
          <h3>{t('slogan')} </h3>
          <p>{t('text')} </p>
        </div>
      </main>
    </section>
  );
}

export default VideoContentBlock;
