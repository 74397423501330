import React from 'react';
import PropTypes from 'prop-types';

import DefaultParagraph from './DefaultParagraph';
import ParagraphWithImage from './ParagraphWithImage';

Paragraph.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape(),
  }),
};
Paragraph.defaultProps = {
  data: null,
};

/**
 * @return {null}
 */
function Paragraph({ data }) {
  if (data === null) {
    return null;
  }
  const { image } = data;

  return image
    ? <ParagraphWithImage data={data} />
    : <DefaultParagraph data={data} />;
}

export default Paragraph;
